//JS Globale che gestisce i video guida

//DEFAULT
var btnTutorial = $(".btn-tutorial");
var divTutorial = $(".div-tutorial");


const sidebarTutorial = $('#sidebar-video-tutorial');

/**
 * 
 */
$(".btn-sidebar-tutorial-open").click(function (event) {
    event.preventDefault();
    sidebarTutorialOpen();
});
$(".btn-sidebar-tutorial-close").click(function (event) {
    event.preventDefault();
    sidebarTutorialClose();
});

$('ul.product-info li a').click(function (event) {
    event.preventDefault();
    $(this).next('div').slideToggle(200);
});

//ALTRI BUTTON CON L'ICONA PLAY NELLA PAGINA
$(".btn-go-tutorial").click(function (event) {
    event.preventDefault();

    let videoId = $(this).data("video");

    $('.btn-go-tutorial').tooltip('hide');

    
    $(`#sidebar-video-tutorial .collapse`).collapse('hide');
    $(`#sidebar-video-tutorial #video_id_${videoId}`).collapse('show');

    sidebarTutorialOpen();


    setTimeout(() => {
        sendActionToVideo("playVideo", videoId);
    }, 400);

});

/**
 * Tooltip
 */
$('.btn-go-tutorial').tooltip({
    'placement': 'top',
    'trigger': "hover"
});


/**
 * Apri sidebar tutoral
 */
function sidebarTutorialOpen() {
    sidebarTutorial.addClass('open');
}
/**
 * Chiudi sidebar tutoral
 */
function sidebarTutorialClose() {
    sidebarTutorial.removeClass('open');

    $(".yt_player_iframe").each(function () {
        this.contentWindow.postMessage(
            '{"event":"command","func":"pauseVideo","args":""}',
            "*"
        );
    });
}

/**
 * Passa un'azione ad uno specifico video di YT
 * @param {*} action 
 * @param {*} videoId 
 */
function sendActionToVideo(action, videoId) {
    let videoIdentifier = "video_yt_" + videoId;
    const targetWindow = document.getElementById(videoIdentifier).contentWindow;
    targetWindow.postMessage(
        '{"event":"command","func":"' + action + '","args":""}',
        "*"
    );
}
