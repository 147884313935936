import Shepherd from 'shepherd.js';
import {steps} from './_steps';
import { getUrlParam , getUrlVars } from '../_utility';




$(document).ready(function () {

    $('#dropdown-help .dropdown-item').on('click' , function(){
        let tourLang = $(this).data( "lang" );
        let tourName = $(this).data( "tour" );

        tutorialRun(tourName , tourLang);
    })

    tutorialResume();

});




function tutorialRun(tourName, tourLang = 'it', stepID ){

    if(!tourName) {
        return;
    }

    const tour = new Shepherd.Tour({
        defaultStepOptions: {
            classes: 'shadow-md bg-purple-dark',
            scrollTo: true,
            exitOnEsc: true,
            confirmCancel: true
        },
        useModalOverlay: true, 
    });

    tour.addSteps( steps[tourLang][tourName] );

    // se settato parte da un id specifico
    if(stepID){
        tour.show(stepID);
    } else {
        tour.start();
    }
}

function tutorialResume(){
    // tourName
    // stepID
    let urlVars = getUrlVars();

    if( !urlVars.tourName ){
        return;
    }
    
    tutorialRun(urlVars.tourName , urlVars.stepID);

}




