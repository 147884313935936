
function goToPage(destination, tourName, stepID = "") {

    var getUrl = window.location;
    var baseUrl = getUrl.protocol + "//" + getUrl.host;

    window.location.href = `${baseUrl}/${destination}?tourName=${tourName}&${stepID}`;
}



var steps = {
    "it": {
        "dati-aziendali": [
            {
                title: 'Dati aziendali',
                text: `<p>Nella sezione dati aziendali ti verranno richiesti i dati relativi alla tua azienda</p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'dati-aziendali-10'
            },
            {
                title: 'Dati aziendali',
                text: `<p>Se hai rapporti economici con AIC, ovvero hai almeno un prodotto nel Prontuario, ti chiediamo di completare la pagina <i>fatturazione</i> contenuta in <i>Dati Aziendali</i></p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Indietro'
                    },
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'dati-aziendali-20'
            },
            {
                title: 'Dati aziendali',
                text: `<p>Nella pagina <i>Logo Aziendale</i> avrai la possibilità di caricare un logo che sarà visibile internamente a questo sito</p>
                       <p>Questo ti permetterà di renderti più riconoscibile dalle aziende con cui collabori a da AIC</p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Indietro'
                    },
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'dati-aziendali-30'
            },
            {
                title: 'Dati aziendali',
                text: `<p>Se desideri che il tuo logo venga pubblicato sul Prontuario potrai anche caricare un file specifico per la stampa</p>
                       <p>Per una migliore resa consigliamo di inserire un file di tipo vettoriale e un raster ad alta risoluzione</p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Indietro'
                    },
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'dati-aziendali-40'
            },
        ],
        "inviti": [
            {
                title: 'Inviti',
                text: `<p>Gli inviti ti permettono di far iscrivere al Prontuario le aziende che producono i tuoi prodotti</p>
                       <p>Le aziende invitate ad iscriversi potranno caricare la documentazione necessaria per l'approvazione dei tuoi prodotti nel Prontuario AIC</p>
                       <p>Gli inviti vengono inviati via email al referente dell'Azienda produttrice</p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'intinviti-10'
            },
            {
                title: 'Inviti',
                text: `<p>Prima di poter inviare un invito ad un'Azienda è necessario inserire i dati Aziendali nell'area <strong>"Produttori"</strong></p>`,
                attachTo: {
                    element: '#menu-item-produttori',
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Indietro'
                    },
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'introduzione-20',
                when: {
                    "before-show": function () {
                        $('#main-sidebar-body').addClass('overflow-fix');
                    },
                    "show": function () {
                        $('#main-sidebar-body').removeClass('overflow-fix');
                    }
                },
            },
            {
                title: 'Inviti',
                text: `<p>Una volta inviato l'invito il referente dell'Azienda riceverà una e-mail contenenti il link alla pagina di registrazione</p>
                       <p>Quando il referente si sarà iscritto, tramite la pagina "Prodotti" potrai associare i tuoi prodotti all'Azienda produttrice in modo che questa possa caricare la documentazione necessaria</p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Indietro'
                    },
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Fine'
                    }
                ],
                id: 'introduzione-30'
            }
        ],
        "utenti": [
            {
                title: 'Utenti',
                text: `<p>Gli utenti sono i <strong>membri della tua Azienda</strong> che collaborano alla raccolta della documentazione dei tuoi prodotti</p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'utenti-10'
            },
            {
                title: 'Utenti',
                text: `<p>Se nella tua Azienda ci sono più persone che lavorano alla raccolta della documentazione potrai creare un utente diverso per ogni collaboratore</p>
                       <p>Ogni utente dovrà avere un indirizzo email differente</p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Indietro'
                    },
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'utenti-20'
            },
            {
                title: 'Utenti',
                text: `<p><strong>Attenzione</strong>: Solo gli amministratori dell'Azienda possono gestire gli utenti</p>
                        <p>Puoi creare due tipi diversi di utente:</p>
                        <ul>
                            <li>
                                <strong>Amministratore</strong> <br>
                                Ha il controllo su tutti gli aspetti Aziendali riguardanti il Prontuario. Può gestire i
                                prodotti, i dati Aziendali e inviti e utenti.
                            </li>
                            <li>
                                <strong>Collaboratore:</strong> <br>    
                                Puo gestire solo gli aspetti riguardanti i prodotti e non può inviare inviti e creare
                                nuovi utenti.
                            </li>
                        </ul>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Indietro'
                    },
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Fine'
                    }
                ],
                id: 'utenti-30'
            },
        ],
        "produttori": [
            {
                title: 'Produttori',
                text: `<p>I Produttori sono le aziende che si occupano di produrre i prodotti a tuo marchio.</p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'produttori-10'
            },
            {
                title: 'Produttori',
                text: `<p>Una volta inserito un Produttore potrai inviargli un invito dalla pagina "Inviti"</p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Indietro'
                    },
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Fine'
                    }
                ],
                id: 'produttori-20'
            }
        ],
        "stabilimenti": [
            {
                title: 'Stabilimenti',
                text: `<p>Nella pagina stabilimenti vedrai la lista di tutti gli stabilimenti in cui avviene la produzione dei tuoi prodotti</p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'stabilimenti-10'
            },
            {
                title: 'Stabilimenti',
                text: `<p><strong>Se sei un'azienda commercializzatrice</strong> vedrai sia gli stabilimenti delle aziende produttrici che collaborano con te, sia gli stabilimenti di tua proprietà (se ne possiedi)</p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Indietro'
                    },
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'stabilimenti-20'
            },
            {
                title: 'Stabilimenti',
                text: `<p><strong>Attenzione</strong>: Se non possiedi stabilimenti di tua proprietà non è necessario aggiungere nuovi stabilimenti</p>
                       <p>Se la produzione è affidata ad atre aziende vedrai i loro stabilimenti una volta che questi saranno associati ai tuoi prodotti</p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Indietro'
                    },
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'stabilimenti-30'
            },
            {
                title: 'Stabilimenti',
                text: `<p>Ogni stabilimento ha il suo <strong>"Piano di Analisi"</strong></p>
                       <p>Questo documento può essere caricato sia dall'aziende produttrice sia dalle aziende commercializzatrice</p>
                       <p>Se sei un'azienda Produttrice che collabora con più aziende commercializzatrici avrai un piano di analisi dedicato per ogni commercializzatore</p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Indietro'
                    },
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'stabilimenti-40'
            }
        ],
        "materie-prime": [
            {
                title: 'Materie Prime a rischio',
                text: `<p>Le materie prime a rischio sono tutte le materie che possono contenere traccie di glutine</p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'materie-prime-10'
            },
            {
                title: 'Materie Prime a Rischio',
                text: `<p>Non è necessario inserie tutte le materie prime ma solo quelle considerate a rischio contaminazione glutine:</p>
                        <ul>
                            <li>Amidi/farinacei Amidi, amidi modificati, prodotti amidacei, farine e fecola di prodotti naturalmente privi di glutine (riso, mais, soia, grano saraceno patate, ecc…)</li>
                            <li>Riso soffiato (potrebbe essere stato estruso con malto)</li>
                            <li>Fibre vegetali (idrolizzate e non)</li>
                            <li>Proteine vegetali (idrolizzate e non)</li>
                            <li>Malto estratto di malto, sciroppo di malto</li>
                            <li>Cacao in polvere</li>
                            <li>Zucchero vanigliato  e/o a velo</li>
                        </ul>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Indietro'
                    },
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Fine'
                    }
                ],
                id: 'materie-prime-20'
            },
        ],
        "aziende-terze": [
            {
                title: 'Aziende Terze',
                text: `<p>Le <i>Aziende Terze</i> sono tutte quelle aziende che non si occupano direttamente della produzione del prodotto ma entrano in contatto con esso</p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'aziende-terze-10'
            },
            {
                title: 'Aziende Terze',
                text: `<p>Tra queste possono esserci, come nel caso della produzione di salumi, un'azienda che si occupa della produzione dell'insaccato (azienda produttrice) e un'altra azienda che si occupa dell'affettatura.</p>
                       <p>Quest'ultima è un'Azienda Terza</p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Indietro'
                    },
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'aziende-terze-20'
            },
            {
                title: 'Aziende Terze',
                text: `<p>Se nel ciclo di produzione del tuo prodotto sono presenti aziende terze dovrai inserire la documentazione nella relativa area</p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Indietro'
                    },
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Fine'
                    }
                ],
                id: 'aziende-terze-30'
            },
        ],
        "prodotti": [
            {
                title: 'Prodotti',
                text: `<p>Nella sezione Prodotti potrai gestire sia i prodotti commercializzati con il tuo marchio (se sei un'azienda commercializzatrice), sia i prodotti che produci per conto di altre aziende</p>
                       <p>Se sei un'azienda produttrice vedrai i prodotti dell'azienda tua cliente solo nel momento in cui ti saranno astati assegnati</p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'prodotti-10'
            },
            {
                title: 'Prodotti',
                text: `<p>Ogni azienda ha il controllo sui prodotti che vengono commercializzati con il proprio marchio.</p>
                       <p>Solo le aziende commercializzatrici del marchio possono creare i prodotti</p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Indietro'
                    },
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'prodotti-20'
            },
            {
                title: 'Prodotti',
                text: `<p>Per ogni prodotto dovrai inserire una o più grammature.</p>
                       <p>La grammatura è il formato con cui viene commercializzato il prodotto</p>
                       <p>Per esempio: un pacco di biscotti potrebbe avere 3 diverse grammature:</p>
                       <ul>
                         <li>500 Gr</li>
                         <li>1 Kg</li>
                         <li>1,5 Kg</li>
                       </ul>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Indietro'
                    },
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'prodotti-30'
            },
            {
                title: 'Prodotti',
                text: `<p>Una volta create le grammature ogni grammatura dovrà essere assegnata ad uno o più produttori tramite il pulsante <strong>"Aggiungi produttore"</strong></p>
                       <p>Per esempio:</p>
                       <ul>
                         <li>
                            <strong>500 Gr</strong> <br>
                            Produttori:
                            <ul>
                                <li>
                                    Azienda A
                                </li>
                                <li>
                                    Azienda B
                                </li>
                            </ul>
                         </li>
                         <li>
                            <strong>1 Kg</strong> <br>
                            Produttori:
                            <ul>
                                <li>
                                    Azienda C
                                </li>
                            </ul>
                         </li>
                         <li>
                            <strong>1,5 Kg</strong> <br>
                            Produttori:
                            <ul>
                                <li>
                                    Azienda B
                                </li>
                            </ul>
                        </li>
                       </ul>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Indietro'
                    },
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'prodotti-40'
            },
            {
                title: 'Prodotti',
                text: `<p>Esempio di creazione di un prodotto</p>
                        <div class="embed-responsive embed-responsive-16by9">
                            <video width="1280" height="768" controls class="embed-responsive-item">
                                <source src="/dist/video/demo-prodotto.mp4" type="video/mp4">
                            </video>
                        </div>`,
                attachTo: {
                    on: 'right'
                },
                classes: 'shepherd-has-video',
                buttons: [
                    {
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Indietro'
                    },
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'prodotti-50'
            }
        ],
        "copie-prontuario": [
            {
                title: 'Copie prontuario',
                text: `<p>Se sei un'azienda commercializzatrice con prodotti nel Prontuario AIC, in questa sezione potrai ordinare le copie del prontuario</p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'aziende-terze-10'
            },
            {
                title: 'Copie prontuario',
                text: `<p>Ogni azienda con prodotti attivi riceverà una copia gratuita. <br>
                       È possibile ordinare ulteriori copie secondo scaglioni predefiniti.</p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Indietro'
                    },
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Avanti'
                    }
                ],
                id: 'copie-prontuario-20'
            },
            {
                title: 'Copie prontuario',
                text: `<p>Gli ordini saranno aperti solamente in un certo periodo dell'anno.</p>
                       <p>Ti contatteremo per informarti della data di apertura e chiusura degli ordini</p>`,
                attachTo: {
                    on: 'right'
                },
                buttons: [
                    {
                        action: function () {
                            return this.back();
                        },
                        secondary: true,
                        text: 'Indietro'
                    },
                    {
                        action: function () {
                            return this.next();
                        },
                        text: 'Fine'
                    }
                ],
                id: 'copie-prontuario-30'
            },
        ],
    }
}


export { steps }; 