import "popper.js";
import "bootstrap4-notify/bootstrap-notify";
import "bootstrap";
import "ekko-lightbox";

// product tour
import "./tutorial/tutorial";

// Videotutorial
import "./_videoTutorial";

window.zxcvbn = require("zxcvbn");

$(document).ready(function () {
  mainSidebar();

  dropdownStates();

  modalTimeline();

  modalCondizioniGenerali();

  guidaUtente();

  aziendaSwitcher();

  aziendaSwitcherGlobale();

  sceltaModalitaPagamento();

  $("#trigger-modal-select-azienda").click(function () {
    $("#modal-select-azienda").modal("show");
  });

  // toggle visibilità password
  $(".input-password-toggle-visibility .btn").click(function () {
    if ($(this).hasClass("active")) {
      $(this).parent().prev().attr("type", "password");
    } else {
      $(this).parent().prev().attr("type", "text");
    }
  });

  $(".tooltip-top").tooltip({
    placement: "top",
  });

  $("#modalButton").click(function () {
    $("#modalImportaGrammature")
      .modal("show")
      .find("#modalContent")
      .load($(this).attr("value"));
  });

  //modali motivazione
  $('a[name="remove-entity"]').click(function (event) {
    let removeAction = event.currentTarget.href;
    let entityId = event.currentTarget.dataset.entityId;

    $(".btn-salva-motivazione").attr("data-entity-id", entityId);
    $(".btn-salva-motivazione").attr("href", removeAction);
  });

  if (!$(".modal-motivazione-text-area").text()) {
    $(".btn-salva-motivazione").addClass("disabled");
  }

  if ($(".motivazione-confermata-text-area").val()) {
    $(".motivazione-confermata-text-area").prop("disabled", true);
  }

  $(".modal-motivazione-text-area").keyup(function () {
    $(".btn-salva-motivazione").removeClass("disabled", false);
    if (!$(".modal-motivazione-text-area").val()) {
      $(".btn-salva-motivazione").addClass("disabled", true);
    }
  });
});

/**
 * Gestione apertura e chiusura sidebar
 */
function mainSidebar() {
  // Apertura
  $(".sidebar-toggler").click(function () {
    let mainWrapper, backdrop;
    mainWrapper = $("#main-wrapper");
    backdrop = $(
      '<div id="sidebar-backdrop" class="modal-backdrop fade"></div>'
    );

    if (mainWrapper.hasClass("sidebar-open")) {
      mainWrapper.removeClass("sidebar-open");
    } else {
      mainWrapper.addClass("sidebar-open");
      backdrop.appendTo(document.body);

      setTimeout(function () {
        backdrop.addClass("show");
      }, 10);

      closeSidebar();
    }
  });

  // Chiusura
  $(".modal-backdrop").click(function () {
    $(this).removeClass("show");

    setTimeout(function () {
      $(".modal-backdrop").remove();
    }, 150);

    $("#main-wrapper").removeClass("sidebar-open");
  });
}

/**
 * Dropdown degli stati
 */
function dropdownStates() {
  $(".dropdown-stato").each((i, dropDownToggle) => {
    dropDownToggle = $(dropDownToggle);
    var dropDownToggleValue = dropDownToggle
      .find(".dropdown-toggle")
      .attr("value");
    dropDownToggle
      .find('.dropdown-menu .btn[value="' + dropDownToggleValue + '"]')
      .addClass("d-none");
    $(".dropdown-stato .dropdown-menu button").click(function () {
      // nascondo l'elemento cliccato
      $(this).addClass("d-none"); // recupero i dati del trigger toggle

      var dropDownToggle = $(this)
        .parents(".dropdown")
        .find(".dropdown-toggle");
      var dropDownToggleValue = dropDownToggle.val(); // mostro nel dropdown-menu l'elemento che verrà sostituito

      $(this)
        .siblings("[value=" + dropDownToggleValue + "]")
        .removeClass("d-none"); // recupero i dati dell'eemento cliccato

      var thisContent = $(this).html();
      var thisColor = $(this).data("color");
      var thisValue = $(this).val(); // setto il nuovo contenuto

      dropDownToggle.html(thisContent); // setto la nuova classe

      dropDownToggle.removeClass(function (index, css) {
        return (css.match(/\bbtn-outline-\S+/g) || []).join(" ");
      });
      dropDownToggle.addClass("btn-outline-" + thisColor); // setto il nuovo value

      dropDownToggle.val(thisValue); // setto il valore nel campo hidden

      $(this)
        .parents(".dropdown")
        .find(".dropdown-stato-value")
        .val(thisValue)
        .trigger("change");
    });
  });
  //var dropDownToggle = $('.dropdown-stato');
  //alert(dropDownToggle.length);
}

/**
 * Gestione modale della timeline
 */
function modalTimeline() {
  $("#btn-timeline").click(function () {
    $("#modal-timeline").modal({
      backdrop: "static",
      keyboard: true,
    });
  });
  $("#btn-modal-timeline-close").click(function () {
    $("#modal-timeline").modal("hide");
  });
}

function modalCondizioniGenerali() {
  let modalCondizioni = $("#modal-condizioni");
  let modalCondizioniDecline = $("#modal-condizioni-decline");

  // apertura modale condizioni
  modalCondizioni.modal({
    backdrop: "static",
    keyboard: false,
  });

  // Validazione check modale condizioni
  $(".check-condizioni-generali").change(function () {
    let btnContinue = $("#modal-condizioni-continue");
    let btnDecline = $("#modal-condizioni-decline");

    if ($(".check-condizioni-generali").not(":checked").length == 0) {
      btnContinue.removeClass("disabled").tooltip("dispose");
    } else {
      btnContinue.addClass("disabled").tooltip({
        placement: "top",
      });
    }
  });

  // rifiuta le condizioni
  $("#btn-modal-condizioni-decline").on("click", function (e) {
    modalCondizioni.modal("hide").on("hidden.bs.modal", function (e) {
      modalCondizioniDecline.modal({
        show: true,
        backdrop: "static",
        keyboard: false,
      });
    });
  });

  // Torna alla modale condizioni
  $("#btn-modal-condizioni-decline-back").on("click", function (e) {
    modalCondizioniDecline.modal("hide").on("hidden.bs.modal", function (e) {
      modalCondizioni.modal({
        show: true,
        backdrop: "static",
        keyboard: false,
      });
    });
  });
}

function guidaUtente() {
  /**
   * Ancore interne indice
   */

  $("#doc-nav a").click(function (event) {
    event.preventDefault();

    let windowWidth = $(window).innerWidth();

    if (windowWidth > 800) {
      $(".doc-col-content").animate(
        {
          scrollTop:
            $($(this).attr("href")).position().top -
            $(".doc-col-wrapper").position().top +
            60,
        },
        "slow"
      );
    } else {
      $("body , html").animate(
        {
          scrollTop: $($(this).attr("href")).offset().top - 80,
        },
        "slow"
      );
    }
  });

  /**
   * Pulsante per tornare all'inizio della pagina
   */
  $("#btn-back-to-top").click(function () {
    $(".doc-col-content").animate(
      {
        scrollTop: 0,
      },
      500
    );
  });

  /**
   * Lightbox per le immagini
   */
  $(".lightbox").click(function () {
    event.preventDefault();
    $(this).ekkoLightbox();
  });
}

/**
 * Gestisce l'apertura e la chiusura delle modali del cambio azienda
 */
function aziendaSwitcher() {
  var modalSelectAzienda = $("#modal-select-azienda");
  var modalSelectAziendaConfirm = $("#modal-select-azienda-confirm");

  // Click sull'azienda
  $(".thumb-select-azienda-btn").click(function () {
    modalSelectAzienda.modal("hide").on("hidden.bs.modal", function (e) {
      modalSelectAziendaConfirm.modal("show");

      modalSelectAzienda.unbind();
    });
  });

  // Click su NO alla conferma
  $("#btn-azienda-switch-annulla").click(function () {
    modalSelectAziendaConfirm.modal("hide").on("hidden.bs.modal", function (e) {
      modalSelectAzienda.modal("show");

      modalSelectAziendaConfirm.unbind();
    });
  });
}

function aziendaSwitcherGlobale() {
  $("#trigger-modal-select-azienda-globale").click(function () {
    $("#modal-select-azienda-globale").modal("show");
  });

  var modalSelectAzienda = $("#modal-select-azienda-globale");
  var modalSelectAziendaConfirm = $("#modal-select-azienda-globale-confirm");

  var url = null;

  // Click sull'azienda
  $(".modal-select-azienda-globale-container .thumb-select-azienda a").click(
    function (e) {
      e.preventDefault();
      url = $(this).attr("href");
      modalSelectAzienda.modal("hide").on("hidden.bs.modal", function (e) {
        modalSelectAziendaConfirm.modal("show");
        modalSelectAzienda.unbind();
      });
      return false;
    }
  );

  // Click su SI alla conferma
  $("#btn-azienda-switch-globale-cambia").click(function () {
    if (url) {
      window.location.href = url;
    }
  });

  // Click su NO alla conferma
  $("#btn-azienda-switch-globale-annulla").click(function () {
    url = null;
    modalSelectAziendaConfirm.modal("hide").on("hidden.bs.modal", function (e) {
      modalSelectAzienda.modal("show");
      modalSelectAziendaConfirm.unbind();
    });
  });
}

/**
 * Gestisce il cambio di metodo di pagamento nascondendo / mostrando il campo iban e il testo con
 * l'iban di AIC
 */
function sceltaModalitaPagamento() {
  var sp = $("#select-payment");

  function updateMP() {
    sp.find(".riba-selected").hide();
    sp.find(".bonifico-selected").hide();

    var val = sp.find("input[type=radio]:checked").first().val();
    if (val) {
      sp.find("." + val + "-selected").show();
    }
  }

  if (sp.length) {
    sp.find("input[type=radio]").on("change", function () {
      updateMP();
    });

    updateMP();
  }
}
